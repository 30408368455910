import {View, StyleSheet} from 'react-native';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
import Text from '@/components/basic/text';
import React from 'react';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import LazyImage from '@/components/basic/image/lazy-image';
import theme from '@/style';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {copyText} from '@/utils';

const copyIcon = require('@components/assets/icons/bets/copy.webp');

export interface BetPanelProps {
  betTime?: string;
  id?: string;
  children?: React.ReactNode;
}

const BetPanel = ({betTime, id, children}: BetPanelProps) => {
  const {i18n} = useTranslation();

  const onCopy = () => {
    if (id) {
      copyText(id);
      globalStore.globalSucessTotal(i18n.t('copy-success'));
    }
  };

  return (
    <View>
      <LinearGradient
        colors={['#FFFFFF', '#FFFFFF00']}
        start={{x: 0, y: 0}}
        end={{x: 0, y: 1}}
        style={[theme.padding.l, styles.header]}>
        <View
          style={[
            theme.flex.row,
            {columnGap: theme.paddingSize.s},
            styles.header,
          ]}>
          <Text
            accent
            // eslint-disable-next-line react-native/no-inline-styles
            style={{fontWeight: '500'}}>
            {i18n.t('bets-page.label.bettingTime')}：
          </Text>
          <Text size="medium" fontFamily="fontInterBold">
            {betTime}
          </Text>
        </View>
        <View
          style={[
            theme.flex.row,
            theme.flex.between,
            theme.flex.centerByCol,
            theme.margin.topl,
          ]}>
          <View style={[theme.flex.row, {columnGap: theme.paddingSize.s}]}>
            <Text
              accent
              // eslint-disable-next-line react-native/no-inline-styles
              style={{fontWeight: '500'}}>
              Order ID：
            </Text>
            <Text
              size="medium"
              // eslint-disable-next-line react-native/no-inline-styles
              style={{fontWeight: '500'}}>
              {id}
            </Text>
          </View>
          <NativeTouchableOpacity onPress={onCopy} style={[styles.copy]}>
            <LazyImage
              imageUrl={copyIcon}
              width={14}
              height={14}
              occupancy="transparent"
            />
            <Text>Copy</Text>
          </NativeTouchableOpacity>
        </View>
      </LinearGradient>
      <View style={[theme.margin.lrl]}>
        <View style={[styles.th]}>
          <View style={[theme.flex.flex1]}>
            <Text>{i18n.t('bets-detail.label.number').toUpperCase()}</Text>
          </View>
          <View
            // eslint-disable-next-line react-native/no-inline-styles
            style={[{width: 96}]}>
            <Text textAlign="center">
              {i18n.t('bets-detail.label.payment').toUpperCase()}
            </Text>
          </View>
          <View
            // eslint-disable-next-line react-native/no-inline-styles
            style={[{width: 96}]}>
            <Text textAlign="right">
              {i18n.t('bets-detail.label.result').toUpperCase()}
            </Text>
          </View>
        </View>
        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  copy: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#D8E0EA',
    paddingHorizontal: 6,
    paddingVertical: 4,
    backgroundColor: '#fff',
    columnGap: 4,
  },
  th: {
    backgroundColor: '#E9ECF5',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
});

export default BetPanel;
